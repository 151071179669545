<template>
  <a-modal
    style="top: 8px;"
    :width="1000"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
    :title="'事务所【'+title+'】余额明细'" >


    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:6}" :wrapper-col="{xs:18}">
      <a-row :gutter="8">
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="经销商">
            <a-input v-model="searchData.dealerName" placeholder="经销商名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="关联单号">
            <a-input v-model="searchData.dealNo" placeholder="请输入关联单号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="交易类型">
            <DictSelect
              field="dealType"
              :value.sync="searchData.dealType"
              style="width: 100%"
              placeholder="请选择交易状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="金额类型">
            <DictSelect
              field="amountType"
              :value.sync="searchData.amountType"
              style="width: 100%"
              placeholder="请选择金额状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="交易时间">
            <DateRange :startTime.sync="searchData.startDate" :endTime.sync="searchData.endDate"></DateRange>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
            <a-button type="primary" @click="getList()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table :columns="columns" :data-source="tableData" :pagination="page" @change="changeTable"
             :rowKey="record => record.id" bordered>
     <span slot="dealType" slot-scope="text">
      <a-tag color="" v-if="text == 1">支付</a-tag>
      <a-tag color="" v-if="text == 2">提现</a-tag>
      <a-tag color="" v-if="text == 3">充值</a-tag>
      <a-tag color="" v-if="text == 4">退款</a-tag>
      <a-tag color="" v-if="text == 5">订单结算</a-tag>
      <a-tag color="" v-if="text == 6">订单取消</a-tag>
      <a-tag color="" v-if="text == 7">提现取消/驳回</a-tag>
      <a-tag color="" v-if="text == 8">订单退款</a-tag>
      <a-tag color="" v-if="text == 9">平台操作</a-tag>
      <a-tag color="" v-if="text == 10">还款</a-tag>
    </span>

      <span slot="payType" slot-scope="text">
      <a-tag color="#87d068" v-if="text == 1">收入</a-tag>
      <a-tag color="#f50" v-if="text == 2">支出</a-tag>
    </span>
      <span slot="beforePay" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="pay" slot-scope="text,row">
        <span style="color: red" v-if="row.payType==2">(-) </span>
        <span style="color: green" v-if="row.payType==1">(+) </span>
        {{ text | formatMoney }}
      </span>
      <span slot="afterPay" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="orderCode" slot-scope="text,row">
        <a @click="checkOrderInfo(row.orderCode,row.dealType)">{{ text }}</a>
      </span>
    </a-table>

    <div style="text-align: right;margin-top: 14px;">
      <a-button type="default" @click="toCancel">关闭</a-button>
      <!--      <a-button type="primary" @click="toSubmit">确认</a-button>-->
    </div>

    <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
    <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
    <FinanceReceiptInfoModal ref="FinanceReceiptInfoModal"></FinanceReceiptInfoModal>
  </a-modal>
</template>

<script>
  import OrderShipInfoEditModal from "@/views/order_ship_info/components/OrderShipInfoEditModal";
  import OrderInfoCheckModal from "@/views/order_info/components/OrderInfoCheckModal";
  import FinanceReceiptInfoModal from '@/views/finance_receipt/components/FinanceReceiptInfoModal';
  const qs = require('qs')
export default {
  name: "FirmBalanceDetailed",
  components: {
    OrderInfoCheckModal,
    OrderShipInfoEditModal,
    FinanceReceiptInfoModal,
  },
  data() {
    return {
      columns: [
        {
          title: '交易时间',
          dataIndex: 'date',
          align: 'center',
          width:220,
          key: 'date',
        },
        {
          title: '经销商名称',
          dataIndex: 'dealerName',
          align: 'center',
          width: 250,
          key: 'dealerName',
        },
        {
          title: '交易前金额（元）',
          dataIndex: 'beforePay',
          align: 'center',
          width: 150,
          key: 'beforePay',
          scopedSlots: {customRender: 'beforePay'}
        },
        {
          title: '交易金额（元）',
          dataIndex: 'pay',
          width: 150,
          align: 'left',
          key: 'pay',
          scopedSlots: {customRender: 'pay'}
        },
        {
          title: '交易后金额（元）',
          dataIndex: 'afterPay',
          width: 150,
          align: 'center',
          key: 'afterPay',
          scopedSlots: {customRender: 'afterPay'}
        },
        {
          title: '交易类型',
          dataIndex: 'dealType',
          align: 'center',
          width: 100,
          key: 'dealType',
          scopedSlots: {customRender: 'dealType'}
        },
        {
          title: '金额类型',
          dataIndex: 'payType',
          align: 'center',
          width: 100,
          key: 'payType',
          scopedSlots: {customRender: 'payType'}
        },
        {
          title: '关联单号',
          dataIndex: 'orderCode',
          align: 'center',
          width: 300,
          key: 'orderCode',
          scopedSlots: {customRender: 'orderCode'}
        },
      ],
      visible: false,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: {y: 600},
      title:'',
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      firmId: 0
    }
  },
  methods: {
    // 查询
    getList() {
      this.page.current = 1
      this.page.pageSize =10
      this.getData();
    },

    // 重置
    reset() {
      this.page.current = 1
      this.page.pageSize =10
      this.searchData = {}
      this.regionalOffice = []
      this.getData();
    },

    //打开上游单据
    checkOrderInfo(orderCode,dealType) {
      console.log(orderCode)
      const data = {
        orderCode: orderCode,
      }
      if(dealType == 3){
        this.$refs.FinanceReceiptInfoModal.setRowDataByCode(orderCode, 'check')
      }else{
        if(orderCode.search('DO') !== -1){
          this.$refs.OrderShipInfoEditModal.setRowDataByCode(data, 'check')
        }else{
          this.$refs.OrderInfoCheckModal.isShowByOrderCode(data, 'check')
        }
      }
    },
    setRowData(row) {
      this.visible = true
      this.firmId = row.id
      this.title = row.name
      this.getData()
    },

    getData() {
      let params = {
        firmId: this.firmId,
        pageSize: this.page.pageSize,
        pageNumber: this.page.current
      }
      // this.axios.get('/api/dealer/dealer/dealerPayRecord/selectFirmId', {params}).then(res => {
      const postData = Object.assign(params, this.searchData)
      this.axios
        .get(`/api/dealer/dealer/dealerPayRecord/selectPayRecordByFirm?${qs.stringify(postData, { arrayFormat: 'repeat' })}`)
        .then((res) => {
        if (res.code === 200) {
          const {total, records} = res.body
          this.page.total = total
          this.tableData = records
        }
      })
    },

    // table分页
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    toCancel() {
      this.visible = false;
    }
  }

}
</script>

<style scoped>
.footer-btns {
  text-align: right;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-top: 20px;
}
</style>